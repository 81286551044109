import React from "react"
import { Link, graphql } from "gatsby"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version

import Layout from "../components/layout"

const ServiceSinglePage = ({ data }) => {
  const servicesArray = data.singleService.edges
  const allServicesArray = data.allServices.edges.filter(
    item => item.node.category_id === servicesArray[0].node.category_id
  )
  return (
    <Layout>
      <div className="rs-breadcrumbs img4">
        <div className="container">
          <div className="breadcrumbs-inner">
            <h1 className="page-title">{servicesArray[0].node.title}</h1>
            <span className="sub-text">
              {servicesArray[0].node.description.split(" ")
                        .splice(0, 25)
                        .join(" ")}}
            </span>
            <ul className="breadcrumbs-area">
              <li title="Go to konstruk">
                <a className="active" href="index">
                  Home
                </a>
              </li>
              <li>{servicesArray[0].node.title}</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="rs-services-single pt-120 pb-120 md-pt-80 md-pb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 md-mb-50">
              <div className="services-img mb-35">
                <img src={servicesArray[0].node.image_url1} alt="" />
              </div>
              <div className="services-title pb-24">
                <h2 className="title">{servicesArray[0].node.title}</h2>
              </div>
              <p className="desc-part pb-24">
                {servicesArray[0].node.description}
              </p>
            </div>
            <div className="col-lg-4 pl-36 md-pl-15">
              <ul className="services-list mb-50">
                {allServicesArray.map((serviceItem, index) => (
                  <li key={index}>
                    <Link
                      className={
                        serviceItem.node.id === servicesArray[0].node.id
                          ? "active"
                          : ""
                      }
                      to={serviceItem.node.slug}
                    >
                      {serviceItem.node.title}
                    </Link>
                  </li>
                ))}
              </ul>
              <div className="services-add mb-50">
                <div className="services-overlay"></div>
                <div className="address-wrap-item mb-20">
                  <div className="icon-part mb-37">
                    <i className="fa fa-phone"></i>
                  </div>
                  <h2 className="title">
                    Have any Questions? Call us <span>Today!</span>
                  </h2>
                  <div className="divider">
                    <span className="divider-separator"></span>
                  </div>
                  <div className="contact-us/">
                    <a href="tel:(604)446-9239">(604) 446-9239</a>
                  </div>
                </div>
              </div>
              <div className="brochures">
                <div className="content-text">
                  <h3 className="title">Brochure</h3>
                  <p className="desc-part">
                    Download our Brochure to know us better in offline and share
                    with others.
                  </p>
                </div>
                <div className="btn-wrapper">
                  <a
                    className="dual-btn"
                    href="/assets/Bradiance-Business-Brochure.pdf"
                    target="_blank"
                  >
                    Download
                  </a>
                  <a
                    className="dual-btn-connector"
                    href="/assets/Bradiance-Business-Brochure.pdf"
                    target="_blank"
                  >
                    Or
                  </a>
                  <a
                    className="rselement-dual-btn"
                    href="/assets/Bradiance-Business-Brochure.pdf"
                    target="_blank"
                  >
                    Discover
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ServiceSinglePage

export const ServicestQuery = graphql`
  query singleSerice($id: Int!) {
    singleService: allMysqlServices(filter: { mysqlId: { eq: $id } }) {
      edges {
        node {
          category_id
          id
          title
          slug
          mysqlId
          description
          image_url1
        }
      }
    }

    allServices: allMysqlServices {
      edges {
        node {
          category_id
          id
          title
          slug
          mysqlId
          description
          image_url1
        }
      }
    }
  }
`
